<template>
  <v-modal name="question-dialog" @before-open="setQuestion" height="auto" width="840">
    <v-card :loading="isLoading">
      <v-card-title class="headline" primary-title>
        {{ isNewQuestion ? 'Kérdés létrehozása' : 'Kérdés szerkesztése' }}
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="isValid">
          <v-row>
            <v-col>
              <v-text-field v-model="question.title" label="Kérdés címe" :rules="[requiredRule]"></v-text-field>
              <p>Kérdés típusa</p>
              <v-radio-group v-model="question.type">
                <v-radio v-for="item in questionTypes" :key="item.id" :label="item.label" :value="item.type"></v-radio>
              </v-radio-group>
              <v-list>
                <v-list-item v-for="(item, index) in question.options" :key="index">
                  <v-list-item-title>
                    <v-text-field v-model="question.options[index].label" :rules="[requiredRule]"></v-text-field>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
              <v-btn small @click="addQuestion"><v-icon size="20" color="black">mdi-plus</v-icon>Új válasz</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text class="mr-2" @click="closeDialog">Bezárás</v-btn>
        <v-btn text color="primary" :loading="isLoading" @click.prevent="submit">Mentés</v-btn>
      </v-card-actions>
    </v-card>
  </v-modal>
</template>
<script>
import { diff } from 'deep-object-diff'

import { UPDATE_QUESTION, CREATE_QUESTION } from '@/store/types'
import formControl from '@/mixins/formControl'

const INITIAL_STATE = {
  title: '',
  options: [
    {
      label: ''
    }
  ]
}

export default {
  name: 'QuestionDialog',
  mixins: [formControl],
  data() {
    return {
      question: { ...INITIAL_STATE },
      editQuestion: {},
      isLoading: false,
      isNewQuestion: null,
      questionTypes: [
        {
          id: 1,
          type: 'radio',
          label: 'Radio'
        },
        {
          id: 2,
          type: 'checkbox',
          label: 'Checkbox'
        }
      ]
    }
  },
  methods: {
    addQuestion() {
      this.question.options.push({
        label: ''
      })
    },
    setQuestion(event) {
      this.isNewQuestion = event.params.isNewQuestion
      this.question = event.params.question
        ? JSON.parse(JSON.stringify({ ...event.params.question }))
        : { ...INITIAL_STATE }

      if (!this.isNewQuestion) {
        this.editQuestion = JSON.parse(JSON.stringify({ ...event.params.question }))
      }
    },
    closeDialog() {
      this.$modal.hide('question-dialog')
    },
    toggleLoading() {
      this.isLoading = !this.isLoading
    },
    async saveQuestion() {
      this.toggleLoading()

      try {
        await this.$store.dispatch(CREATE_QUESTION, this.question)
        this.closeDialog()
      } catch (error) {
        return Promise.reject(error)
      } finally {
        this.toggleLoading()
      }
    },
    async updateQuestion() {
      this.toggleLoading()

      try {
        const questionDiff = diff(this.editQuestion, this.question)
        await this.$store.dispatch(UPDATE_QUESTION, { questionId: this.editQuestion.id, payload: questionDiff })
        this.closeDialog()
      } catch (error) {
        return Promise.reject(error)
      } finally {
        this.toggleLoading()
      }
    },
    submit() {
      this.validate()

      if (!this.isValid) {
        return
      }

      if (this.isNewQuestion) {
        this.saveQuestion()
      } else {
        this.updateQuestion()
      }
    }
  }
}
</script>

<style lang="scss">
.v-card__subtitle {
  padding: 0;
  font-weight: 500;

  .theme--light & {
    color: #111 !important;
  }
}
</style>
